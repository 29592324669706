import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { imageUrl, server } from "../server";

function Search() {
  const navigate = useNavigate();
  const [visibleDiv, setVisibleDiv] = useState("restaurant");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const showRestaurant = () => {
    setVisibleDiv("restaurant");
  };

  const showBar = () => {
    setVisibleDiv("bar");
  };

  const restaurantAPI = `${server}/restaurantItems.php`;
  const [fetchRestaurantData, setfetchRestaurantData] = useState([]);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${restaurantAPI}`);
        setfetchRestaurantData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchRestaurantData();
  }, []);

  const restaurantGroupedData = fetchRestaurantData.reduce((acc, item) => {
    const { category } = item;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const barAPI = `${server}/barItems.php`;
  const [fetchBarData, setfetchBarData] = useState([]);

  useEffect(() => {
    const fetchBarData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${barAPI}`);
        setfetchBarData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchBarData();
  }, []);

  const barGroupedData = fetchBarData.reduce((acc, item) => {
    const { category } = item;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <>
      <div className="container-fluid sticky-top bg-white py-1">
        <div className="row">
          <div
            className="col-1 d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <i className="bi bi-chevron-left fs-4"></i>
          </div>
          <div className="col-11">
            <div class="input-group">
              <input
                type="text"
                class="form-control text-font search-bar rounded-start-4 ps-4 no-outline"
                placeholder="Search"
              />
              <span class="input-group-text search-bar rounded-end-4 pe-4">
                <i className="bi bi-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="defaultitemslist">
        <div className="container-fluid px-2 mt-2">
          <div className="d-flex flex-row overflow-y-scroll">
            <div
              className={`rounded-pill tab-pill-button px-4 py-1 m-1 ${
                visibleDiv === "restaurant" ? "bg-black" : null
              }`}
              onClick={showRestaurant}
            >
              <p
                className={`fs-8 my-2 fw-medium ${
                  visibleDiv === "restaurant" ? "text-white" : "text-black"
                }`}
              >
                Restaurant
              </p>
            </div>
            <div
              className={`rounded-pill tab-pill-button px-4 py-1 m-1 ${
                visibleDiv === "bar" ? "bg-black" : null
              }`}
              onClick={showBar}
            >
              <p
                className={`fs-8 my-2 fw-medium ${
                  visibleDiv === "bar" ? "text-white" : "text-black"
                }`}
              >
                Bar
              </p>
            </div>
          </div>
        </div>

        {visibleDiv === "restaurant" && (
          <div className="container-fluid pb-4 px-3 my-3">
            {Object.keys(restaurantGroupedData).map((category) => (
              <div key={category}>
                <div className="d-flex align-items-center mb-3 mt-4">
                  <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                    {category}
                  </h6>
                  <hr className="hr-style" />
                </div>
                {restaurantGroupedData[category].map((item) => (
                  <div className="mb-3" key={item.itemId}>
                    <div className="row g-2">
                      <div className="col-7 d-flex flex-column justify-content-center">
                        <div className="mt-2 pe-4">
                          <img
                            src={`/assets/images/${item.contains}.jpg`}
                            height="12"
                            className="mb-1"
                          />
                          <p className="fs-7 fw-medium m-0">{item.name}</p>
                          <p className="fs-9 mb-0 fw-medium">₹{item.mrp}</p>
                          <div className="d-flex align-items-center my-1">
                            <img
                              src="/assets/images/icons/star.png"
                              width="10"
                              alt="star icon"
                            />
                            <p className="fs-9 mb-0 ms-1">
                              {item.rating} • {item.time} mins
                            </p>
                          </div>
                          <p className="fs-9 mb-0">{item.description}</p>
                        </div>
                      </div>
                      <div className="col-5">
                        <img
                          src={`${imageUrl}/${item.image}`}
                          className="rounded-4 w-100 object-fit-cover"
                          height="100"
                          alt={item.name}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        {visibleDiv === "bar" && (
          <div className="container-fluid pb-4 px-3 my-3">
            {Object.keys(barGroupedData).map((category) => (
              <div key={category}>
                <div className="d-flex align-items-center mb-3 mt-4">
                  <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                    {category}
                  </h6>
                  <hr className="hr-style" />
                </div>
                {barGroupedData[category].map((item) => (
                  <div className="mb-3" key={item.itemId}>
                    <div className="row g-2">
                      <div className="col-7 d-flex flex-column justify-content-center">
                        <div className="mt-2">
                          <p className="fs-7 fw-medium m-0">{item.name}</p>
                          <p className="fs-9 mb-0 fw-medium">₹{item.mrp}</p>
                          <div className="d-flex align-items-center my-1">
                            <img
                              src="/assets/images/icons/star.png"
                              width="10"
                              alt="star icon"
                            />
                            <p className="fs-9 mb-0 ms-1">
                              {item.rating} • {item.time} mins
                            </p>
                          </div>
                          <p className="fs-9 mb-0">{item.description}</p>
                        </div>
                      </div>
                      <div className="col-5">
                        <img
                          src={`${imageUrl}/${item.image}`}
                          className="rounded-4 w-100 object-fit-cover"
                          height="100"
                          alt={item.name}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-light px-3 py-5">
        <img src="assets/images/logo.png" width="150" />
        <div className="mt-3">
          <p className="fs-7 mb-0">Copyright &copy; 2024</p>
          <p className="fs-7 mb-0">Developed by Nybaex Pvt. Ltd.</p>
        </div>
      </div>
    </>
  );
}

export default Search;
