import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { imageUrl, server } from "../server";

function App() {
  const navigate = useNavigate();
  const [visibleDiv, setVisibleDiv] = useState("restaurant");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const showRestaurant = () => {
    setVisibleDiv("restaurant");
  };

  const showBar = () => {
    setVisibleDiv("bar");
  };

  const restaurantAPI = `${server}/restaurantItems.php`;
  const [fetchRestaurantData, setfetchRestaurantData] = useState([]);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${restaurantAPI}`);
        setfetchRestaurantData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchRestaurantData();
  }, []);

  const barAPI = `${server}/barItems.php`;
  const [fetchBarData, setfetchBarData] = useState([]);

  useEffect(() => {
    const fetchBarData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${barAPI}`);
        setfetchBarData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchBarData();
  }, []);

  return (
    <>
      <div className="container-fluid mt-2 mb-4">
        <div className="row g-3">
          <div className="col-6" onClick={showRestaurant}>
            <div
              className={`py-3 px-3 ${
                visibleDiv === "restaurant"
                  ? "home-top-card-light"
                  : "home-top-card"
              } restaurantButton`}
            >
              <div className="my-1">
                <h6 className="fw-medium title-font text-light-dark mb-1">
                  Restaurant
                </h6>
                <p className="fs-8 text-secondary mb-2">
                  Enjoy your
                  <br />
                  favourite treats
                </p>
                <i className="bi bi-arrow-right fs-7 text-dark"></i>
              </div>
            </div>
            <div className="d-flex justify-content-end home-top-card-img">
              <img
                src="/assets/images/restaurant.png"
                className=""
                width="80"
              />
            </div>
          </div>
          <div className="col-6" onClick={showBar}>
            <div
              className={`py-3 px-3 ${
                visibleDiv === "bar" ? "home-top-card-light" : "home-top-card"
              } barButton`}
            >
              <div className="my-1">
                <h6 className="fw-medium title-font text-light-dark mb-1">
                  Bar
                </h6>
                <p className="fs-8 text-secondary mb-2">
                  Enjoy your
                  <br />
                  favourite treats
                </p>
                <i className="bi bi-arrow-right fs-7 text-dark"></i>
              </div>
            </div>
            <div className="d-flex justify-content-end home-top-card-img-2">
              <img src="/assets/images/bar.png" className="" height="60" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2 sticky-top bg-white py-1">
        <div className="rounded-4 search-bar px-4">
          <Link to="/search">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 text-secondary">Search</p>
              <i className="bi bi-search text-secondary"></i>
            </div>
          </Link>
        </div>
      </div>

      {visibleDiv === "restaurant" && (
        <div className="restaurant">
          <div className="container-fluid px-3 py-4">
            <div className="row g-2 mt-1">
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/chicken-briyani.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Chicken
                    <br />
                    Briyani
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹349</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img src="/assets/images/restaurant/pasta.png" height="50" />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Pasta
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹329</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/chicken-tandoori.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Chicken
                    <br />
                    Tandoori H/F
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹379</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/fish-curry.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Fish
                    <br />
                    Curry
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹499</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/gulab-jamun.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Gulab
                    <br />
                    Jamun
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹129</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/paneer-tikka.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Paneer
                    <br />
                    Tikka
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹289</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src="/assets/images/restaurant/noodles.png"
                    height="50"
                  />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Noodles
                    <br />
                    Veg
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹269</p>
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img src="/assets/images/restaurant/salad.png" height="50" />
                  <p className="fs-small text-center lh-1 mt-2 mb-1 text-light-dark">
                    Green
                    <br />
                    Salad
                  </p>
                  <p className="fs-9 mb-2 fw-medium">₹149</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                  Top Selling Items
                </h6>
                <hr className="hr-style" />
              </div>
            </div>
            <div className="d-flex overflow-x-scroll overflow-y-hidden">
              <div className="ps-3"></div>

              {fetchRestaurantData.slice(0, 6).map((item) => {
                const {
                  itemId,
                  name,
                  description,
                  mrp,
                  image,
                  rating,
                  time,
                  contains,
                } = item;
                return (
                  <div className="col-5" key={itemId}>
                    <div className="me-1">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="pe-3"></div>
            </div>
          </div>

          <div className="container-fluid px-3 pb-4 my-2">
            <div className="d-flex flex-row flex-wrap">
              <div className="rounded-pill bg-gray px-4 py-1 m-1">
                <p className="fs-8 my-1 fw-medium">Pure Veg</p>
              </div>
              <div className="rounded-pill bg-gray px-4 py-1 m-1">
                <p className="fs-8 my-1 fw-medium">Non Veg</p>
              </div>
              <div className="rounded-pill bg-gray px-4 py-1 m-1">
                <p className="fs-8 my-1 fw-medium">Ratings 4.0+</p>
              </div>
            </div>
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                  Top Rated Items
                </h6>
                <hr className="hr-style" />
              </div>
              <div className="row g-2">
                {fetchRestaurantData.slice(6, 14).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    time,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container-fluid px-3 pb-4 mb-2">
            <div className="d-flex align-items-center mb-3">
              <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                Top picks for you
              </h6>
              <hr className="hr-style" />
            </div>
            {fetchRestaurantData.slice(14, 18).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                time,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <img
                    src={`${imageUrl}/${image}`}
                    className="w-100 rounded-top-4 object-fit-cover"
                    height="200"
                  />
                  <div className="px-3 py-4 rounded-bottom-4 shadow">
                    <div>
                      <img
                        src={`/assets/images/${contains}.jpg`}
                        height="12"
                        className="mb-1"
                      />
                      <p className="fs-7 fw-medium m-0">{name}</p>
                      <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                      <div className="d-flex align-items-center my-1">
                        <img src="/assets/images/icons/star.png" width="10" />
                        <p className="fs-9 mb-0 ms-1">
                          {rating} • {time} mins
                        </p>
                      </div>
                      <p className="fs-9 mb-0">{description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="container-fluid pb-4 px-3">
            <div className="d-flex align-items-center mb-3">
              <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                Explore more dishes
              </h6>
              <hr className="hr-style" />
            </div>
            {fetchRestaurantData.slice(18, 30).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                time,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <div className="row g-2">
                    <div className="col-7 d-flex flex-column justify-content-center">
                      <div className="mt-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="100"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="row g-2">
                {fetchRestaurantData.slice(30, 50).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    time,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container-fluid pb-4 px-3">
            {fetchRestaurantData.slice(50, 80).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                time,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <div className="row g-2">
                    <div className="col-7 d-flex flex-column justify-content-center">
                      <div className="mt-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="100"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="row g-2">
                {fetchRestaurantData.slice(80, 120).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    time,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating} • {time} mins
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {visibleDiv === "bar" && (
        <div className="bar">
          <div className="py-4 my-2">
            <div className="d-flex overflow-x-scroll overflow-y-hidden">
              <div className="ps-3"></div>
              {fetchBarData.slice(0, 6).map((item) => {
                const { itemId, name, description, mrp, image, rating } =
                  item;
                return (
                  <div className="col-5" key={itemId}>
                    <div className="me-1">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="pe-3"></div>
            </div>
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                  Top Rated Items
                </h6>
                <hr className="hr-style" />
              </div>
              <div className="row g-2">
                {fetchBarData.slice(6, 14).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container-fluid px-3 pb-4 mb-2">
            <div className="d-flex align-items-center mb-3">
              <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                Top picks for you
              </h6>
              <hr className="hr-style" />
            </div>
            {fetchBarData.slice(14, 18).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <img
                    src={`${imageUrl}/${image}`}
                    className="w-100 rounded-top-4 object-fit-cover"
                    height="200"
                  />
                  <div className="px-3 py-4 rounded-bottom-4 shadow">
                    <div>
                      <img
                        src={`/assets/images/${contains}.jpg`}
                        height="12"
                        className="mb-1"
                      />
                      <p className="fs-7 fw-medium m-0">{name}</p>
                      <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                      <div className="d-flex align-items-center my-1">
                        <img src="/assets/images/icons/star.png" width="10" />
                        <p className="fs-9 mb-0 ms-1">
                          {rating}
                        </p>
                      </div>
                      <p className="fs-9 mb-0">{description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="container-fluid pb-4 px-3">
            <div className="d-flex align-items-center mb-3">
              <h6 className="fw-medium title-font text-light-dark mb-0 me-3 pb-1 text-nowrap">
                Explore more
              </h6>
              <hr className="hr-style" />
            </div>
            {fetchBarData.slice(18, 30).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <div className="row g-2">
                    <div className="col-7 d-flex flex-column justify-content-center">
                      <div className="mt-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="100"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="row g-2">
                {fetchBarData.slice(30, 50).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container-fluid pb-4 px-3">
            {fetchBarData.slice(50, 80).map((item) => {
              const {
                itemId,
                name,
                description,
                mrp,
                image,
                rating,
                contains,
              } = item;
              return (
                <div className="mb-3" key={itemId}>
                  <div className="row g-2">
                    <div className="col-7 d-flex flex-column justify-content-center">
                      <div className="mt-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                    <div className="col-5">
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="100"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pb-4">
            <div className="px-3">
              <div className="row g-2">
                {fetchBarData.slice(80, 120).map((item) => {
                  const {
                    itemId,
                    name,
                    description,
                    mrp,
                    image,
                    rating,
                    contains,
                  } = item;
                  return (
                    <div className="col-6 mb-3" key={itemId}>
                      <img
                        src={`${imageUrl}/${image}`}
                        className="rounded-4 w-100 object-fit-cover"
                        height="180"
                      />
                      <div className="mt-2 ms-2">
                        <img
                          src={`/assets/images/${contains}.jpg`}
                          height="12"
                          className="mb-1"
                        />
                        <p className="fs-7 fw-medium m-0">{name}</p>
                        <p className="fs-9 mb-0 fw-medium">₹{mrp}</p>
                        <div className="d-flex align-items-center my-1">
                          <img src="/assets/images/icons/star.png" width="10" />
                          <p className="fs-9 mb-0 ms-1">
                            {rating}
                          </p>
                        </div>
                        <p className="fs-9 mb-0">{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-light px-3 py-5">
        <img src="assets/images/logo.png" width="150" />
        <div className="mt-3">
          <p className="fs-7 mb-0">Copyright &copy; 2024</p>
          <p className="fs-7 mb-0">Developed by Nybaex Pvt. Ltd.</p>
        </div>
      </div>
    </>
  );
}

export default App;
